export default {
  methods: {
    getHeadForPage(entry) {
      const head = {
        meta: [
          {
            hid: 'og:title',
            property: 'og:title',
            content: entry.title,
          },

          {
            hid: 'og:url',
            property: 'og:url',
            content: `${this.$config.app.url}${entry.url}`,
          },
          {
            id: 'og:type',
            property: 'og:type',
            content: entry.blueprint === 'blog_post' ? 'blog' : 'website',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: entry.preview_image
              ? entry.preview_image.url
              : `${this.$config.app.url}/icon.png`,
          },
          {
            hid: 'og:image:alt',
            property: 'og:image:alt',
            content: entry.preview_image
              ? entry.preview_image.alt
              : 'Atramex Logo',
          },
        ],
        script: [],
      }

      if (entry.image) {
        head.meta.push({
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        })
      } else {
        head.meta.push({
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        })
      }
      if (entry.seo_hidden) {
        head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
      }

      const title = entry.seo_title || entry.title
      if (title) {
        head.title = title
      }

      const description = entry.seo_description || entry.description
      if (description) {
        head.meta.push({
          hid: 'description',
          name: 'description',
          content: description,
        })
        head.meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: description,
        })
      }

      head.meta.push({
        hid: 'keywords',
        property: 'keywords',
        content: [entry.article_nr, entry.product_nr, entry.dimension_unit]
          .filter(Boolean)
          .join(', '),
      })

      // Structured data
      if (entry.blueprint === 'home_page') {
        head.script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'Atramex Webshop',
            url: this.$config.app.url,
          },
        })

        head.script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'Atramex',
            legalName: 'Atramex AG',
            url: this.$config.app.url,
            logo: `${this.$config.app.url}/icon.png`,
            foundingDate: '1962',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+41 61 765 15 15',
              email: 'mail@atramex.ch',
              contactType: 'customer service',
              areaServed: 'CH',
            },
          },
        })
      }

      if (entry.blueprint === 'product') {
        const product = {
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: entry.title,
          sku: `${entry.article_nr} ${entry.product_nr}`,
          image: [entry.preview_image?.url ?? ''],
          description: entry.description,
          brand: {
            '@type': 'Organization',
            name: 'Atramex AG',
          },
          offers: [
            {
              '@type': 'Offer',
              url: `${this.$config.app.url}${entry.url}`,
              availability: 'https://schema.org/InStock',
              itemCondition: 'https://schema.org/NewCondition',
              price: entry.gross_price,
              priceCurrency: 'CHF',
              priceValidUntil:
                Math.ceil(new Date().getFullYear() + 1) + '-12-31',
            },
          ],
        }

        head.script.push({
          type: 'application/ld+json',
          json: product,
        })
      }

      if (entry.breadcrumbs) {
        const breadcrumbs = entry.breadcrumbs.map((breadcrumb, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.title,
            item: `${this.$config.app.url}${breadcrumb.url}`,
          }
        })

        head.script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs,
          },
        })
      }

      return head
    },
  },
}
