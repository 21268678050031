
import SeoFunctions from '~/mixins/SeoFunctions.js'
import { getPageByUri } from '~/graphql/queries.graphql'

export default {
  mixins: [SeoFunctions],

  provide() {
    return {
      blueprint: this.page?.entry?.blueprint,
    }
  },

  async asyncData({ $graphql, route, error, $ray }) {
    // $ray({ uri, origin: '_top/_slug.vue' })

    const page = await $graphql.cms
      .request(getPageByUri, { uri: route.path })
      .catch((error) => {
        console.error(error)
        if (error?.response?.data) {
          return error.response.data
        } else {
          error({ statusCode: 500, message: 'Server Error' })
        }
      })

    if (page && page.entry === null) {
      error({ statusCode: 404, message: 'Page Not Found' })
    }

    return { page }
  },

  head() {
    return this.getHeadForPage(this.page.entry)
  },

  computed: {
    pageType() {
      const type = this.page.entry.__typename

      if (type === 'Entry_Jobs_Job') {
        return 'ContentPage'
      }

      if (type.startsWith('Entry_')) {
        return type.replace(/Entry_[^_].*_/g, '')
      }

      return ''
    },
  },
}
